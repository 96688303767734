import React from 'react';
import { getRedirectUri } from '../utils';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { AuthContext } from '../contexts/AuthContext';
import { toast, SignupForm, Button, ButtonType } from '@om/design-system';
import Address from "../models/Address";

class Signup extends React.Component<any> {
	state = {
		referrer: undefined,
		password: "",
		phone: "",
		valid: false,
	}

	submit = (event: any) => {
		event.preventDefault();
		var formData = new FormData(document.getElementById("signup-form") as HTMLFormElement);
		const { user } = this.context as any;

		if(!formData.get("gender")) {
			toast.show({ content: t('signup.errorGender'), isError: true });
			return;
		}

		const address = Address.mapFromReachfive({
			default: true,
			country: formData.get(this.names.country),
			postalCode: formData.get(this.names.postcode),
		});

		const data = {
			givenName: formData.get(this.names.firstName),
			familyName: formData.get(this.names.lastName),
			email: formData.get(this.names.email),
			password: formData.get(this.names.password),
			phone_number: this.state.phone || user?.phoneNumber || null,
			birthdate: !!formData.get(this.names.birthdate) ? formData.get(this.names.birthdate) : null,
			gender: formData.get(this.names.gender),
			addresses: [address],
			consents: {
				"om_consents": {
					"date": new Date(),
					"granted": formData.get(this.names.consents) === "on",
					"consent_type": "opt-in"
				}
			}
		};

		window.r5.signup({
			data,
			auth: {
				responseType: 'code',
				persistent: true,
				redirectUri: getRedirectUri(),
			},
			redirectUrl: getRedirectUri()
		})
		.catch((error: any) => {				
			toast.show({ content: error.errorUserMsg, isError: true });
		})
	}

	onChangePhone = (phone: any) => {
		this.setState({ phone });
	}

	names = {
		signup_form: `signup-form`,
		email: `email`,
		password: `password`,
		firstName: `given_name`,
		lastName: `family_name`,
		gender: `gender`,
		birthdate: `birthdate`,
		phone: `phone`,
		country: 'country',
		postcode: 'postcode',
		consents: `consents`
	};

	render() {
		const { t } = this.props;
		const { valid } = this.state;
		const { user } = this.context as any;

		if (valid) {
			return <div className='wrapper-content not-connected'>
				<div className='center-content-size'>
					<form>
						<h1>{t('signup.title')}</h1>
						<label>{t('signup.confirm.description')}</label>
						<Button type={ButtonType.Link} href='/' className='center'>{t('backLogin')}</Button>
					</form>
				</div>
			</div>
		}

		return (
			<div className='wrapper-content not-connected'>
				<div className='center-content-size'>
					<SignupForm onSubmit={this.submit} names={this.names} user={user} onChangePhone={this.onChangePhone}/>
					<hr />
					<Button type={ButtonType.Link} href='/' className='center'>{t('backLogin')}</Button>
				</div>
			</div>
		);
	}
}

Signup.contextType = AuthContext;
export default withTranslation()(Signup)